<template>
  <div>
    <div class="introduction_bg">
      <img src="../../static/image/introduction_bg.png" />
    </div>
    <div class="introduction_about">
      <div class="introduction_about_bg">
        <div class="introduction_about_title">
          <i class="introduction_about_title_zn">公司介绍 </i>
          <i class="introduction_about_title_en"> / About us</i>
        </div>
        <div class="introduction_about_title_line"></div>
      </div>
      <!-- <div class="introduction_about_img">
        <img src="../../static/image/introduction_1.png" />
        <img src="../../static/image/introduction_2.png" />
      </div> -->
      <div class="introduction_about_content">
        四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能，同时发挥资产数字化拍卖平台的优势，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。
        <br/>
        <div class="introduction_about_content_line"></div>
        中峪数交的成立，是推动行业发展，推进产业创新的一种有效尝试，对相关产业多元化的有益补充。通过对交易模式的创新与优化组合，既增强了市场流动性又为企业、交易商提供了商品买卖等全方位一体化的服务，以稳定商品价格、降低企业经营成本，扩大生产企业知名度，增加生产企业销售渠道，缓解企业资金需求，从而减少企业经营风险，提升企业收益，同时，以国家政策为导向，以大众创业，万众创新为初心，服务于各生产企业的同时，为市场培育孵化更多优质的人才。
      </div>
    </div>
    <div class="introduction_culture">
      <div class="introduction_culture_bg">
        <div class="introduction_culture_title">
          <i class="introduction_culture_title_zn">企业文化 </i>
          <i class="introduction_culture_title_en"> / Corporate culture</i>
        </div>
        <div class="introduction_culture_title_line"></div>
      </div>
      <div class="introduction_culture_list">
        <div
          class="introduction_culture_list_item"
          :class="item.show?'introduction_culture_list_itemRed':''"
          v-for="(item, index) in cultureList"
          @mouseenter="enterImg(index)"
          @mouseleave="outImg(index)"
          :key="item.name"
        >
          <img
            :src="imgList[index]"
            class="introduction_culture_list_item_name"
            v-show="!item.show"
          />
          <div class="introduction_culture_list_item_value" v-show="item.show">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="introduction_qualification">
      <div class="introduction_qualification_bg">
        <div class="introduction_qualification_title">
          <i class="introduction_qualification_title_zn">企业资质 </i>
          <i class="introduction_qualification_title_en">
            / Enterprise qualification</i
          >
        </div>
        <div class="introduction_qualification_title_line"></div>
      </div>
      <div class="introduction_qualification_img">
        <img
          src="../../static/image/introduction_3.jpg"
          class="introduction_qualification_img_left"
        />
        <img src="../../static/image/introduction_4.jpg" />
      </div>
      <div class="introduction_qualification_phone">
        <div class="introduction_qualification_phone_call">联系电话：400-185-3168</div>
        <div>公司地址：浙江省绍兴市柯桥区金昌国际金融中心（裙楼）2F</div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "introduction",
  data() {
    return {
      cultureList: [
        {
          show: false,
          name: "文化理念",
          value: "至专至精 财富共赢",
        },
        {
          show: false,
          name: "合作精神",
          value: "持诚信互利共荣 以远见共谋发展",
        },
        {
          show: false,
          name: "员工理念",
          value: "专业进取锲而不舍",
        },
        {
          show: false,
          name: "企业用人观",
          value: "以德为首以智为本以诚为准",
        },
        {
          show: false,
          name: "员工训词",
          value: "以严谨的态度做事 用感恩的心做人",
        },
      ],
      imgList: [
        require("../../static/image/文化理念.png"),
        require("../../static/image/合作精神.png"),
        require("../../static/image/员工理念.png"),
        require("../../static/image/企业用人观.png"),
        require("../../static/image/员工训词.png"),
      ],
    };
  },
  computed: {},
  mounted() {
    window.scroll(0,0);
    this.getWXInfo() 
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪数交企业介绍', // 分享标题
            desc: '四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能，同时发挥资产数字化拍卖平台的优势，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/854a68f9990f4a9784bdf229ec1b46f1.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    enterImg(index) {
      this.cultureList[index].show = true;
    },
    outImg(index) {
      this.cultureList[index].show = false;
    },
  },
};
</script>
<style scoped lang="scss">
.introduction_bg {
  background: #f7f7f7;
  min-width: 1240px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.introduction_about {
  width: 100%;
  min-width: 1240px;
  padding: 50px 0;
  background: #f7f7f7;
  .introduction_about_bg {
    width: 1240px;
    margin: 0 auto;
    text-align: center;
    .introduction_about_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }
    .introduction_about_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }
    .introduction_about_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 30px auto;
    }
  }
  .introduction_about_img {
    display: flex;
    margin: 0 auto;
    width: 1240px;
    justify-content: space-between;
    img {
      width: calc((100% - 36px) / 2);
    }
  }
  .introduction_about_content {
    width: 1200px;
    padding: 0 20px;
    margin: 30px auto;
    font-size: 20px;
    font-weight: 500;
    text-align: justify;
    color: #1d1b19;
    line-height: 34px;
    letter-spacing: 1px;
    font-family: 宋体;
    .introduction_about_content_line{
      height: 40px;
      width: 100%;
    }
  }
}
.introduction_culture {
  width: 100%;
  padding: 100px 0;
  .introduction_culture_bg {
    width: 1240px;
    margin: 0 auto;
    text-align: center;
    .introduction_culture_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }
    .introduction_culture_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }
    .introduction_culture_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 50px auto;
    }
  }
  .introduction_culture_img {
    display: flex;
    margin: 0 auto;
    width: 1240px;
    justify-content: space-between;
    img {
      width: calc((100% - 36px) / 3);
    }
  }
  .introduction_culture_content {
    width: 1240px;
    margin: 90px auto;
    font-size: 20px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 34px;
    letter-spacing: 1px;
  }

  .introduction_culture_list {
    display: flex;
    width: 1200px;
    padding: 0 20px;
    margin: 50px auto;
    justify-content: space-between;
    .introduction_culture_list_item {
      width: 200px;
      height: 200px;
      text-align: center;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.04);
      position: relative;
      .introduction_culture_list_item_name {
        margin-top: 55px;
      }
      .introduction_culture_list_item_value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 黑体;
        font-size: 24px;
        color: #ffffff;
        letter-spacing: 1px;
        text-align: left;
      }
    }
    .introduction_culture_list_itemRed{
      background-color: #9E1307;
    }
  }
}

.introduction_qualification {
  width: 100%;
  padding: 100px 0;
  background: #f7f7f7;
  .introduction_qualification_bg {
    width: 1240px;
    margin: 0 auto;
    text-align: center;
    .introduction_qualification_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }
    .introduction_qualification_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }
    .introduction_qualification_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 90px auto;
    }
  }
  .introduction_qualification_img {
    display: flex;
    margin: 0 auto;
    width: 1240px;
    justify-content: center;
    .introduction_qualification_img_left {
      margin-right: 66px;
    }
    img {
      width: calc((100% - 36px) / 3);
    }
  }
  .introduction_qualification_phone {
    margin-top: 74px;
    font-size: 16px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 22px;
    display: flex;
    justify-content: center;
    .introduction_qualification_phone_call{
      margin-right: 26px;
    }
  }
  .introduction_qualification_content {
    width: 1240px;
    margin: 90px auto;
    font-size: 20px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 34px;
    letter-spacing: 1px;
  }
}
</style>
